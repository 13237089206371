import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import {fetchToken, initializeAuthorization} from "../../actions/loginActions";
import ArgoAppBar from "../common/ArgoAppBar";


class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            rememberMe: true,
            error: ""
        };
        this.props.initializeAuthorization();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.username !== this.state.username) {
            this.setState({username: nextProps.username ?? ""});
        }
    }

    textFieldOnChangeEvent = (fieldName, event) => {
        this.setState({[fieldName]: event.target.value});
    };

    textFieldOnKeyDownEvent = (event) => {
        if (event.key === "Enter") {
            this.logIn(event);
        }
    };

    logIn = () => {
        this.props.fetchToken(this.state.username, this.state.password, this.state.rememberMe);
    };


    render() {
        if (this.props.loggedIn) {
            return (this.props.children);
        }

        return (
            <div style={{height: "100%", minHeight: "100%", width: "100%", overflow: "hidden"}}>
                <ArgoAppBar showMenuIconButton={false}/>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '100vh' }}
                >
                    <Grid item xs={3}>
                        <Card sx={{minWidth: "400px", minHeight: "260px", border: "1px solid", borderColor: "black", boxShadow: "3px 5px lightgray"}}>
                            <CardHeader title="Please sign in to continue"
                                        titleTypographyProps={{
                                            fontSize: "1.000rem",
                                            fontWeight: "bold",
                                            lineHeight: "normal"
                                        }}
                            />
                            <CardContent>
                                <TextField
                                    id="username"
                                    name="username"
                                    variant="standard"
                                    label="User name"
                                    fullWidth
                                    value={this.state.username !== "" ? this.state.username : ""}
                                    onChange={this.textFieldOnChangeEvent.bind(this, "username")}
                                    onKeyDown={this.textFieldOnKeyDownEvent.bind(this)}
                                />
                                <div style={{height: "20px"}}>&nbsp;</div>
                                <TextField
                                    id="password"
                                    name="password"
                                    variant="standard"
                                    type="password"
                                    label="Password"
                                    autoComplete='off'
                                    fullWidth
                                    value={this.state.password !== "" ? this.state.password : ""}
                                    onChange={this.textFieldOnChangeEvent.bind(this, "password")}
                                    onKeyDown={this.textFieldOnKeyDownEvent.bind(this)}
                                />
                                <div>{
                                    this.props.message !== "" ?
                                        <div style={{backgroundColor: "#eeeeee", color: "red"}}>
                                            {this.props.message}
                                        </div> : ""
                                    }
                                </div>
                            </CardContent>
                            <CardActions style={{height: "38px", float: "right", paddingRight: "16px"}}>
                                <Button id="Login"
                                        name="Login"
                                        variant="contained"
                                        primary="true"
                                        onClick={this.logIn}
                                        disabled={this.state.username === "" || this.state.password === ""}
                                >Sign In</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}


LoginPage.propTypes = {
    children: PropTypes.node.isRequired,
    username: PropTypes.string,
    loggedIn: PropTypes.bool,
    message: PropTypes.string,
    loading: PropTypes.bool,
    fetchToken: PropTypes.func,
    initializeAuthorization: PropTypes.func
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchToken, initializeAuthorization}, dispatch);
}

function mapStateToProps(state) {
    return {
        username: state.login.username,
        loggedIn: state.login.loggedIn,
        message: state.login.message,
        loading: state.login.loading
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
