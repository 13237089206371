import React, {Component, useContext} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import {questionnaireTemplateInit,
    getQuestionnaireTemplate,
    getQuestionnaireReference,
    getMetadataRequiredTokens,
    saveQuestionnaireTemplate,
    clearQuestionnaireReference}
from "../../actions/questionnaireTemplateActions";
import {initGetTemplateList} from "../../actions/templateListActions";
//import {updateQuestionnaireTemplate} from "../../actions/questionnaireEditorActions";
import {formatDateDisplay, toLocale} from "../../utilities/dateFormats";
import ArgoAppBar from "../common/ArgoAppBar";
import ArgoTextFieldHook from "../common/ArgoTextFieldHook";
import ArgoTextFieldSelectHook from "../common/ArgoTextFieldSelectHook";
import ArgoButtonHook from "../common/ArgoButtonHook";
import {QD_PORTAL_PATH, QD_URL} from '../../constants/systemConstants';
//import * as metadataTemplateTypes from "../../constants/metadataTemplateTypes";
// import {getMetadataDefinitions} from "../../actions/metadataDefinitionActions";
import * as portal from "../../utilities/portal";
import blueTheme from "../../blueTheme";
import {ALLOWED_TOKEN} from "../../constants/dataConstants";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ArgoIconButtonHook from "../common/ArgoIconButtonHook";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import * as expander from "../../utilities/expander";
import TemplateApi from "../../api/TemplateApi";
import * as metadataUtils from "../../utilities/metadata";
import ArgoSectionOpenCloseHook from "../common/ArgoSectionOpenCloseHook";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import * as TEMPLATE from "../../constants/templateConstants";
import {useNavigate} from "react-router-dom";
import Switch from '@mui/material/Switch';
import FormControlLabel from "@mui/material/FormControlLabel";
import {display} from "../../constants/templateConstants";


const leftCurlyBracket = "{";
const rightCurlyBracket = "}";
const standardIndent = "50px";
const metadataIndent = "90px";

// const testObj = {
//     "questionnaireTemplateId": 8,
//     "userKey": "blake",
//     "referenceUrl": "http://www.google.com",
//     "metadata": {
//         "DateOfEvent": "6/30/2020",
//         "FinancialInstitution": "ARGO Bank (Richardson) Branch",
//         "ChannelName": "Contact Center",
//         "NameOfProduct": "Personal Loan  ",
//         "TypeOfProduct": "Product Type",
//         "OperatorName": "Sally P."
//     }
// };


class QuestionnaireSampleSurveyDrawer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            readToRender: false,
            isPublished: false,
            sendJsonNotSet: true,
            sendJson: {metadata: {}},
            templateSendJson: "",
            fullMetadataTokenArray: ALLOWED_TOKEN,
            unusedMetadataTokenArray: ALLOWED_TOKEN,
            disableAdd: false,
            metadataCurrentIndex: 0,
            metadataArray: [],
            newMetadataToken: "",
            newMetadataValue: "",
            metadataSaved: false,
            tokensArr: [],
            metadataArr: [],
            metadataErrorText: "",
            totalErrorText: "",
            sectionMetadataOpen: false,
            getPreviousProperties: false,
            requiredTokens: [],
            displayToggle: {display: true, done: false, noPrevPublished: false}
        };
    }



    componentDidMount() {
        this.getTestDataAndRequiredTokens(this.props.template.templateKey, this.props.template.id)
    }


    componentWillReceiveProps(nextProps) {

        if (this.state.sendJsonNotSet) {
            let tempQuestionnaireTemplate = {...nextProps.questionnaireTemplate[nextProps.template.templateKey]};

            if (tempQuestionnaireTemplate) {

                let sendJson = {metadata: {}};
                let templateSendJson = {metadata: {}};

                if (tempQuestionnaireTemplate.metadata !== undefined && tempQuestionnaireTemplate.metadata.hasOwnProperty("sendJSON")) {
                    sendJson = JSON.parse(tempQuestionnaireTemplate.metadata["sendJSON"]);
                    templateSendJson = tempQuestionnaireTemplate.metadata["sendJSON"];
                }

                let unusedMetadataTokenArray = this.state.fullMetadataTokenArray;
                for (let key in sendJson.metadata) {
                    unusedMetadataTokenArray = unusedMetadataTokenArray.filter(e => e !== key); // remove used token
                }

                let metadataArray = [];
                for (let key in sendJson.metadata) {
                    if (sendJson.metadata.hasOwnProperty(key)) {
                        metadataArray.push({token: [key], value: sendJson.metadata[key]});
                    }
                }

                let isPublished = false;

                let publishedDate = nextProps.template.publishedDate;
                if (publishedDate !== null) {

                    let todayDate = new Date();

                    let currentDayOfMonth = todayDate.getDate();
                    let currentMonth = todayDate.getMonth() +1; // Be careful! January is 0, not 1
                    let currentYear = todayDate.getFullYear();

                    let todayYearMonthDay = currentYear.toString() + ((currentMonth < 10) ? ("0" + currentMonth) :  currentMonth) + ((currentDayOfMonth < 10) ? ("0" + (currentDayOfMonth)) : currentDayOfMonth);

                    let startDateYearMonthDay = formatDateDisplay(nextProps.template.startDate,"YearMonthDay");
                    let endDateYearMonthDay = formatDateDisplay(nextProps.template.endDate,"YearMonthDay");

                    //let startDateYearMonthDay = startDate.substr(6,4) + startDate.substr(0,2) + startDate.substr(3,2);
                    //let endDateYearMonthDay = endDate.substr(6,4) + endDate.substr(0,2) + endDate.substr(3,2);


                    if ((endDateYearMonthDay === "" ) && (startDateYearMonthDay <= todayYearMonthDay)) {
                        isPublished = true;
                    } else if ((startDateYearMonthDay <= todayYearMonthDay) && (endDateYearMonthDay > todayYearMonthDay)) {
                        isPublished = true;
                    }

                }

                this.setState({
                    readToRender: true,
                    isPublished: isPublished,
                    sendJsonNotSet: false,
                    sendJson: sendJson,
                    templateSendJson: templateSendJson,
                    metadataArray: metadataArray,
                    unusedMetadataTokenArray: unusedMetadataTokenArray
                });


                nextProps.clearQuestionnaireReference();
            }
        }


        if(!this.state.displayToggle.done && nextProps.questionnaireTemplate?.[nextProps.template.templateKey]?.["testData"] !== undefined) {
            this.setState({displayToggle:
                    {display: Object.entries(nextProps.questionnaireTemplate?.[nextProps.template.templateKey]?.["testData"]).length === 0,
                    done: true}})
        }
        this.createTokensAndMetadataProperties(nextProps)
    }

    createTokensAndMetadataProperties = (props) => {
        let allFilledProps = props.questionnaireTemplate?.[props.template.templateKey]?.["testData"]
        let allFilledTokensArr = [];
        let allFilledMetadataArr = []
        let requiredTokens = this.state.getPreviousProperties ? this.state.requiredTokens : props.questionnaireRequiredTokens
        if (allFilledProps && requiredTokens) {
            for (const key in allFilledProps) {
                if(requiredTokens.includes(key)) allFilledTokensArr.push([key, allFilledProps[key]]);
                else if(key === "testMode") {}
                else if(this.state.getPreviousProperties && props.questionnaireRequiredTokens.includes(key)) {}
                else allFilledMetadataArr.push([key, allFilledProps[key]]);
            }
            for (const token of requiredTokens) {
                if(!allFilledTokensArr.some(entry => entry[0] === token)) allFilledTokensArr.push([token, ""])
            }
        }
        this.setState({tokensArr: allFilledTokensArr,
                            metadataArr: allFilledMetadataArr,
                            requiredTokens: requiredTokens},
            () => this.checkForErrorsOnScreen())
    }

    getTestDataAndRequiredTokens = (key, id) => {
        this.props.getQuestionnaireTemplate(key, id);
        this.props.getMetadataRequiredTokens(id)
    }

    checkForErrorsOnScreen = () => {
        let metadataErrorText = "";
        let totalErrorText = "";
        if(this.state.metadataArr.some(entry => entry[0] === "")) metadataErrorText = "Metadata name cannot be blank"

        if(this.state.metadataArr.some(entry => entry[1] === ""))
            metadataErrorText !== "" ? metadataErrorText += ", Metadata values cannot be blank" : metadataErrorText = "Metadata values cannot be blank"

        if(this.state.tokensArr.some(entry => entry[1] === ""))
            metadataErrorText !== "" ?  metadataErrorText += ", Token values cannot be blank" : metadataErrorText = "Token values cannot be blank"

        const noDuplicateKeys = new Set()

        //checks for duplicate by creating a set and comparing size
        this.state.tokensArr.forEach(([key]) => noDuplicateKeys.add(key))
        this.state.metadataArr.forEach(([key]) => noDuplicateKeys.add(key))
        if(noDuplicateKeys.size !== this.state.tokensArr.length + this.state.metadataArr.length)
            metadataErrorText !== "" ?  metadataErrorText += ", Duplicate Token/Metadata names" : metadataErrorText = "Duplicate Token/Metadata names"

        if(this.state.metadataArr.some(entry => entry[0] === "testMode"))
            metadataErrorText !== "" ? metadataErrorText += ", Metadata name cannot be testMode" : metadataErrorText = "Metadata name cannot be testMode"

        if(metadataErrorText !== "") totalErrorText = "Resolve metadata errors"

        if(!this.state.isPublished) {
            if(totalErrorText !== "") totalErrorText += ", Questionnaire is not published"
            else totalErrorText = "Questionnaire is not published"
        }
        this.setState({metadataErrorText: metadataErrorText, totalErrorText: totalErrorText,disableAdd: metadataErrorText === "" ? false : true})
    }


    onSelectChange = (event, fieldName, menuItemText) => {
        let value = event.target.value;
        this.setState({[fieldName]: value});
        value = this.state.unusedMetadataTokenArray[value];
    };


    onTextChange = (name, event, fieldName, index) => {
        let newValue = event.target.value;
        if(fieldName.includes("newRequiredTokensValue")){
            let tempTokensArr = JSON.parse(JSON.stringify(this.state.tokensArr))
            tempTokensArr[index][1] = newValue
            this.setState({tokensArr: tempTokensArr}, () => this.checkForErrorsOnScreen())
        }
        else if(fieldName.includes("newMetadataValue")){
            let tempMetadataArr = JSON.parse(JSON.stringify(this.state.metadataArr))
            tempMetadataArr[index][1] = newValue
            this.setState({metadataArr: tempMetadataArr}, () => this.checkForErrorsOnScreen())
        }
        else {
            let tempMetadataArr = JSON.parse(JSON.stringify(this.state.metadataArr))
            tempMetadataArr[index][0] = newValue
            this.setState({metadataArr: tempMetadataArr}, () => this.checkForErrorsOnScreen())
        }
    };


    addTokenAndValueLine = (addToSendObj, key, value, indentPx = "60px", index = 0, isURL = false, valueBlack = false) => {

        let displayValue = (value === null) ? "" : value;

        if (addToSendObj && (key !== "") && (value !== "{") && (value !== "}")) {

            let tempSendJson = {...this.state.sendJson};
            let jsonChanged = false;

            if (!tempSendJson.hasOwnProperty("metadata")) {
                tempSendJson["metadata"] = {};
            }

            // If not metadata and key does not exist then add key
            if ((indentPx !== metadataIndent) && !tempSendJson.hasOwnProperty(key)) {
                tempSendJson[key] = value;
                jsonChanged = true;
            }
            else if ((indentPx === metadataIndent) && (!tempSendJson.metadata.hasOwnProperty(key))) {
                tempSendJson.metadata[key] = value;
                jsonChanged = true;
            }

            if (jsonChanged) {
                this.setState({sendJson: tempSendJson});
            }
        }

        return <div key="div01" style={{display: "flex", alignItems: "center", width: "100%"}}>
            <div key={key + "1" + index} style={{width: indentPx, paddingTop: "10px"}}>&nbsp;</div>
            <div key={key + "2" + index}>
                {key === "" ?
                    <div key="div02" style={{float: "left", fontSize: "14pt", color: "black"}}>{key}</div>
                    :
                    <div key="div03" style={{float: "left", fontSize: "14pt", color: "red"}}>{'"' + key + '"'} &nbsp;</div>
                }
            </div>
            <div key={key + "3" + index}>
                {key === "" ?
                    <div key="div04" style={{float: "left", fontSize: "14pt"}}>{key}</div>
                    :
                    <div key="div05" style={{float: "left", fontSize: "14pt"}}>:</div>
                }
            </div>
            <div key={key + "4" + index} style={{float: "left", fontSize: "14pt", }}>&nbsp;</div>
            <div key={key + "5" + index}>
                {isURL ?
                    <div key="div05" style={{float: "left", fontSize: "14pt", color: "blue", textDecoration: "underline"}}>{'"' + displayValue + '"'}</div>
                    :
                    <div key="div06" style={{float: "left", fontSize: "14pt", color: valueBlack ? "black" : "blue"}}>{valueBlack ? displayValue : ('"' + displayValue + '"')}</div>
                }
            </div>
            <div key={key + "6" + index} style={{paddingLeft: "15px"}}>
                &nbsp;&nbsp;&nbsp;
                {((indentPx === metadataIndent) && (index !== -1)) ?

                    <ArgoButtonHook
                        fileName="QuestionnaireSampleSurveyDrawer"
                        fieldName="remove"
                        label="REMOVE"
                        width={100}
                        onClick={this.removeMetadata}
                    />
                    :
                    ""
                }
            </div>
            <div key={key + "7" + index} style={{float: "clear"}}></div>
        </div>

    };


    metadataAddLine = () => {

        return <div key="metadataAddLine01" style={{display: "flex", width: "100%"}}>

            <div key="metadataAddLine03-ADD-button" style={{alignItems: "left", paddingTop: '14px'}}>
                <ArgoButtonHook
                    fileName="QuestionnaireSampleSurveyDrawer"
                    fieldName="add"
                    label="ADD"
                    width={100}
                    style={{textAlign:'center',padding:"10px"}}
                    onClick={this.changeMetadataAddModeToFalse}
                />
            </div>

            <div key="metadataAddLine03" style={{float: "clear"}}></div>
        </div>
    };


    addEmptyMetadata = () => {
        let tempMetadataArr = JSON.parse(JSON.stringify(this.state.metadataArr))
        tempMetadataArr.push(["", ""])
        this.setState({metadataArr: tempMetadataArr}, () => this.checkForErrorsOnScreen());
    };


    displayMetadataLine = (name = "", value = "", index) => {
        return <div key="displayMetadataLine01" style={{display: "flex", width: "100%"}}>

            <div id="QuestionnaireSampleSurveyDrawer-metadata-container-div" style={{float: "left", paddingTop: "10px"}}>
                <ArgoTextFieldHook
                    fileName="QuestionnaireSampleSurveyDrawer"
                    fieldName={`newMetadataName${index}`}
                    index={index}
                    label="Metadata Name"
                    value={name}
                    onChange={this.onTextChange.bind(this, name)}
                    width="200px"
                />
            </div>

            <div id="QuestionnaireSampleSurveyDrawer-spacer-div-2" style={{float: "left", fontSize: "14pt", paddingTop: "30px"}}>&nbsp; : &nbsp;</div>

            <div id="QuestionnaireSampleSurveyDrawer-spacer-div-3" style={{float: "left", fontSize: "14pt", width: "10px"}}>&nbsp;</div>

            <div  key="displayMetadataLine06" style={{float: "left", fontSize: "14pt", paddingTop: "10px" }} >
                <ArgoTextFieldHook
                    fileName="QuestionnaireSampleSurveyDrawer"
                    fieldName={`newMetadataValue${index}`}
                    index={index}
                    label="Metadata Value"
                    value={value}
                    onChange={this.onTextChange.bind(this, name)}
                    width="300px"
                />
            </div>

            <div id="QuestionnaireSampleSurveyDrawer-spacer-div-4" style={{float: "left", fontSize: "14pt"}}>&nbsp;</div>

            <ArgoIconButtonHook
                fileName="QuestionnaireSampleSurveyDrawer"
                fieldName="remove"
                onClick={this.removeMetadata.bind(this, index)}
                index={this.props.index}
                icon={<HighlightOffOutlinedIcon style={{color: expander.ICON_COLOR}}/>}
                display={this.props.showIconButtonRemove}
            />
            <div id="QuestionnaireSampleSurveyDrawer-clear-div" style={{float: "clear"}}></div>
        </div>

    };


    saveMetadata = () => {

        //let tokenExists = false;

        if (this.state.newMetadataToken) {
            let newMetadataToken = this.state.unusedMetadataTokenArray[this.state.newMetadataToken]
            let metadataArray = this.state.metadataArray; // get current used metadata tokens
            let unusedMetadataTokenArray = this.state.unusedMetadataTokenArray.filter(e => e !== newMetadataToken); // remove used token

            metadataArray.push({token: newMetadataToken, value: this.state.newMetadataValue});

            this.setState({metadataAddMode: true, newMetadataToken: "", newMetadataValue: "", metadataCurrentIndex: (this.state.metadataCurrentIndex + 1), unusedMetadataTokenArray: unusedMetadataTokenArray, metadataArray: metadataArray});

        }
        else {
            this.setState({metadataAddMode: true});
        }
    };


    cancelMetadata = () => {
        this.setState({metadataAddMode: true, newMetadataToken: "", newMetadataValue: ""});
    };

    removeMetadata = (index) => {
        let tempMetadataArr = JSON.parse(JSON.stringify(this.state.metadataArr))
        tempMetadataArr.splice(index, 1)
        this.setState({metadataArr: tempMetadataArr}, () => this.checkForErrorsOnScreen());
    };

    displayRequiredTokens = (name, value, index) => {
        return <div key="displayRequiredTokensLine01" style={{display: "flex", width: "100%"}}>
            <div id="QuestionnaireSampleSurveyDrawer-RequiredTokens-container-div"
                 style={{float: "left", paddingTop: "10px"}}>

                <ArgoTextFieldHook
                    fileName="QuestionnaireSampleSurveyDrawer"
                    fieldName={`newRequiredTokensName${index}`}
                    label="Token Name"
                    value={name}
                    disabled={true}
                    width="200px"
                />
            </div>

            <div id="QuestionnaireSampleSurveyDrawer-spacer-div-2"
                 style={{float: "left", fontSize: "14pt", paddingTop: "30px"}}>&nbsp; : &nbsp;</div>

            <div id="QuestionnaireSampleSurveyDrawer-spacer-div-3"
                 style={{float: "left", fontSize: "14pt", width: "10px"}}>&nbsp;</div>

            <div key="displayMetadataLine06" style={{float: "left", fontSize: "14pt", paddingTop: "10px"}}>
                <ArgoTextFieldHook
                    fileName="QuestionnaireSampleSurveyDrawer"
                    fieldName={`newRequiredTokensValue${index}`}
                    index={index}
                    label="Token Value"
                    value={value}
                    onChange={this.onTextChange.bind(this, name)}
                    width="300px"
                />
            </div>
        </div>
    }


    sendJSON = () => {
        let tempSendJson =
            {...Object.fromEntries(this.state.tokensArr), ...Object.fromEntries(this.state.metadataArr), testMode: true}

        TemplateApi.updateQuestionnaireMetadata(tempSendJson, this.props.template.id).then(() => {
            this.getTestDataAndRequiredTokens(this.props.template.templateKey, this.props.template.id)
            this.createTokensAndMetadataProperties(this.props)
        })

        // NOTE: because the sendJson object is saved in the questionnaire metadata
        // when moved between servers the questionnaire.id will change and is required
        // when you GET a questionnaire reference key.  This was causing portal to
        // malfunction getting the wrong metadata for the template.
        // {...Object.fromEntries(this.state.tokensArr), ...Object.fromEntries(this.state.metadataArr)}
        tempSendJson = {metadata:{...Object.fromEntries(this.state.tokensArr), ...Object.fromEntries(this.state.metadataArr), testMode: true}};
        // tempSendJson.referenceUrl = "http://localhost:3001/qd2portal/"; // NO more sending reference URL per Nirav
        tempSendJson.questionnaireTemplateId = this.props.template.id;
        this.props.getQuestionnaireReference(tempSendJson);
    };


    saveJSON = () => {

        let updatedTemplate = JSON.parse(JSON.stringify(this.props.questionnaireTemplate[this.props.template.templateKey]));

        let tempSendJson = {metadata: this.state.sendJson.metadata};
        updatedTemplate.metadata["sendJSON"] = JSON.stringify(tempSendJson);

        updatedTemplate.startDate = null;
        updatedTemplate.endDate = null;
        updatedTemplate.notes = null;

        this.setState({isPublished: false, templateSendJson: updatedTemplate.metadata["sendJSON"], metadataSaved: true}, this.props.saveQuestionnaireTemplate(updatedTemplate));
    };


    closeDrawer = () => {
        if (this.state.metadataSaved) {
            this.props.savePortalReferenceJson(this.closeCreatePortalRefKeyDrawer);  // Tell questionnaire list screen that it needs to relaod because portal reference key was saved
        }

        this.props.closeDrawer();
    };

    onClickToggleSectionOpenClose = () => {
        this.setState({sectionMetadataOpen: !this.state.sectionMetadataOpen});
    };

    openVersionsScreen = (key, name, nameQualifier) => {

        this.props.initGetTemplateList(this.props.versionsFilter, this.props.templateType, TEMPLATE.MODE.VERSIONS, key, name, nameQualifier, this.props.defaultActions);

        let path = QD_URL + "/" + this.props.templateType + "-versions/" + key;
        this.props.navHook(path, {replace: true});
        this.closeDrawer();
    }

    getPreviousValues = (event) => {
        if(event.target.checked) {
            TemplateApi.getTemplateVersions(this.props.template.templateKey).then(data => {
                let publishedTemplates = data.filter(template => template.status.name === "Published")
                publishedTemplates.pop();
                if(publishedTemplates.length > 0) {
                    let latestTemplate = undefined;
                    for(let i = publishedTemplates.length - 1; i >= 0; i--) {
                        if(latestTemplate) break;
                        if(Object.entries(publishedTemplates[i]?.testData).length > 0) latestTemplate = publishedTemplates[i];
                    }
                    if(latestTemplate)
                        this.setState({getPreviousProperties: true}, () => this.getTestDataAndRequiredTokens(latestTemplate.templateKey, latestTemplate.id))
                    else
                        this.setState({displayToggle: {
                                display: false,
                                done: this.state.displayToggle.done,
                                noPrevPublished: true}})
                }
                else {
                    this.setState({displayToggle: {
                        display: false,
                            done: this.state.displayToggle.done,
                        noPrevPublished: true}})
                }
            })
        }
        else if(event.target.checked === false) {
            this.setState({getPreviousProperties: false}, () => this.getTestDataAndRequiredTokens(this.props.template.templateKey, this.props.template.id))
        }
    }


    render() {
        const test = blueTheme;

        let currJsonString = JSON.stringify(this.state.sendJson);
        let savedJsonString = JSON.stringify(this.state.templateSendJson);

        let refKey = this.props.questionnaireReference.hasOwnProperty("key") ? this.props.questionnaireReference.key : "";
        //let refReadOnlyKey = this.props.questionnaireReference.hasOwnProperty("readOnlyKey") ? this.props.questionnaireReference.readOnlyKey : "";


        let referenceUrl = "refUrl"// portal.getPortalRefURL();
        let rtnJsonObj = this.props.questionnaireReference;
        return (
            <ClickAwayListener onClickAway={(event) => {
                // Checks is target is BODY first because portal preview DDLBs cause target to be body and we shouldn't close on that
                if (event.target.tagName !== 'BODY') this.closeDrawer()
            }}>
                <div key="baseDiv" style={{height: "100%"}}>
                    {this.state.readToRender ?
                    <div key="baseDiv" style={{height: "100%"}}>
                        <ArgoAppBar
                            title={"Sample Survey Creation:"}
                            titleFor={this.props.template.name}
                            showMenuArrowButton="LEFT"
                            onLeftIconButtonTouchTap={this.closeDrawer}
                            isDrawer={true}
                            width={questionnaireSampleSurveyDrawerProps.width}
                        />

                        <div style={{height: '64px'}}></div>

                        {this.state.isPublished ?
                            <div key="MainParentDivSendJson" style={{height: "90%"}}>
                                <div key="SendJson02" style={{height: "40px", paddingLeft: "40px"}}><h2><b>Sample Survey
                                    Creation</b></h2></div>
                                <div key="SendJson03" style={{height: "10px"}}>&nbsp;</div>
                                {this.state.displayToggle.display ?
                                <FormControlLabel id="toggleKey" style={{paddingLeft: "40px", margin: "0px"}}
                                    control={<Switch color="primary"/>}
                                    label="Fill in values from the last published version?"
                                    labelPlacement="start"
                                    onChange={this.getPreviousValues.bind(this)}
                                /> : this.state.displayToggle.noPrevPublished ?
                                        <div style={{paddingLeft: "40px", color: "red", fontSize: "18px"}}>
                                            <b>No previously published version or previous test data exists</b>
                                        </div>
                                        : ""}
                                <div key="SendJson05" style={{display: "flex"}}>
                                    {this.state.tokensArr.length > 0 ?
                                        <div key="SendJson06" style={{paddingLeft: "40px"}}>
                                            <div key="SendJson07" style={{height: "40px"}}><h3><b>Required Tokens</b>
                                            </h3></div>
                                            <div key="SendJson08" style={{height: "10px"}}>&nbsp;</div>
                                            <div key="SendJsonRequiredTokens">
                                                {this.state.tokensArr.map(([name, value], index) =>
                                                    <div key={`SendJson${index}`}>{this.displayRequiredTokens(name, value, index)}</div>)
                                                }
                                            </div>
                                        </div> : ""
                                    }
                                    <div style={{paddingLeft: "40px"}}>
                                        <div key="SendJson09" style={{height: "40px"}}><h3><b>Additional
                                            Metadata</b>
                                        </h3></div>
                                        <div key="SendJson10" style={{height: "10px"}}>&nbsp;</div>
                                        {this.state.metadataArr.length > 0 ?
                                            <div key="SendJsonMetadata">
                                                {this.state.metadataArr.map(([name, value], index) =>
                                                    <div
                                                        key={`SendJson${index}`}>{this.displayMetadataLine(name, value, index)}</div>)
                                                }
                                            </div> : ""
                                        }
                                        <div key="SendJsonMetadataAddMode">
                                            <div key="metadataAddLine01" style={{display: "flex", width: "100%"}}>
                                                <div key="metadataAddLine03-ADD-button"
                                                     style={{alignItems: "left", paddingTop: '14px'}}>
                                                    <ArgoButtonHook
                                                        fileName="QuestionnaireSampleSurveyDrawer"
                                                        fieldName="add"
                                                        label="ADD"
                                                        width={100}
                                                        style={{textAlign: 'center', padding: "10px"}}
                                                        disabled={this.state.disableAdd}
                                                        onClick={this.addEmptyMetadata}
                                                        canHaveErrorMessage={true}
                                                        errorMessage={this.state.metadataErrorText}
                                                    />
                                                </div>
                                                <div key="metadataAddLine03" style={{float: "clear"}}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div key="OuterButtonContainer" style={{display: "flex", width: "100%"}}>
                                    <div key="SendButtonContainer"
                                         style={{float: "left", paddingTop: '14px', paddingLeft: "40px"}}>
                                        <ArgoButtonHook
                                            fileName="QuestionnaireSampleSurveyDrawer"
                                            fieldName="send"
                                            onClick={this.sendJSON.bind(this)}
                                            label={"SEND"}
                                            width={100}
                                            disabled={this.state.disableAdd || !this.state.isPublished}
                                            canHaveErrorMessage={true}
                                            errorMessage={this.state.totalErrorText}
                                        />
                                    </div>
                                </div>

                                <div key="RtnJsonContainer">
                                    {Object.keys(rtnJsonObj).length === 0 ? "" :
                                        <div style={{paddingLeft: "40px"}}>
                                            <div><b style={{fontSize: "20px"}}>Key: </b> {rtnJsonObj["key"]}</div>
                                            <div><b style={{fontSize: "20px"}}>Read Only
                                                Key: </b> {rtnJsonObj["readOnlyKey"]}</div>
                                            <div><b style={{fontSize: "20px"}}>Survey URL: </b>
                                                <a href={rtnJsonObj["referenceUrl"]}
                                                   target="_blank">{rtnJsonObj["referenceUrl"]}</a>
                                            </div>
                                            <div><b style={{fontSize: "20px"}}>Survey URL (Read Only): </b>
                                                <a href={rtnJsonObj["readOnlyUrl"]}
                                                   target="_blank">{rtnJsonObj["readOnlyUrl"]}</a>
                                            </div>
                                            <div key="rtnJson01" style={{height: "40px"}}>&nbsp;</div>
                                            <div key="rtnJson02" style={{height: "20px"}}><b style={{fontSize: "20px"}}>Return
                                                Details</b></div>
                                            <ArgoSectionOpenCloseHook
                                                fileName="QuestionnaireSampleSurveyDrawer"
                                                fieldName="sectionMetadataOpen"
                                                sectionTitle={"All Properties:"}
                                                onClick={this.onClickToggleSectionOpenClose}
                                                open={this.state.sectionMetadataOpen}
                                            />
                                            {this.state.sectionMetadataOpen ?
                                                <pre key="rtnJson03" style={{width: "100%", fontSize: "16px"}}>
                                                {JSON.stringify(rtnJsonObj, null, 4)}
                                            </pre> : ""
                                            }
                                            <div key="rtnJson01" style={{height: "40px"}}>&nbsp;</div>
                                        </div>
                                    }
                                </div>
                            </div> :
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <Card sx={{
                                    marginTop: "20%",
                                    boxShadow: "7px 7px 20px 2px rgba(0, 0, 0, 0.2), -7px -7px 20px 3px rgba(0, 0, 0, 0.14)",
                                    width: "40%"
                                }}>
                                    <CardHeader title="***Unpublished Questionnaire***"
                                                titleTypographyProps={{
                                                    fontSize: "1.000rem",
                                                    fontWeight: "bold",
                                                    lineHeight: "normal"
                                                }}
                                    />
                                    <CardContent>
                                        <Typography variant="h5" id="modal-title"
                                                    style={{color: "red", fontWeight: "bold"}}>
                                            Warning: Cannot create sample survey from an unpublished questionnaire
                                        </Typography>
                                        <Typography variant="subtitle1" id="simple-modal-description"
                                                    style={{marginTop: "1%", alignItems: "center"}}>
                                            Please go back and publish questionnaire or click "Versions" to create sample
                                            survey from previous version
                                        </Typography>
                                    </CardContent>
                                    <div key="metadataAddLine03-ADD-button"
                                         style={{display: "flex", justifyContent: "center", paddingBottom: '20px'}}>
                                        <ArgoButtonHook
                                            fileName="QuestionnaireSampleSurveyDrawer"
                                            fieldName="versions"
                                            label="Versions"
                                            width={100}
                                            style={{textAlign: 'center', padding: "10px"}}
                                            onClick={this.openVersionsScreen.bind(this, this.props.template.templateKey, this.props.template.name, this.props.template.nameQualifier)}
                                        />
                                    </div>
                                </Card>
                            </div>
                        }
                    </div> : ""}
                </div>
            </ClickAwayListener>
        );
    }
}


QuestionnaireSampleSurveyDrawer.defaultProps = {};

QuestionnaireSampleSurveyDrawer.propTypes = {
    templateId: PropTypes.number,
    template: PropTypes.object,
    questionnaireTemplate: PropTypes.object,
    questionnaireReference: PropTypes.object,
    questionnaireRequiredTokens: PropTypes.array,
    questionnaireTemplateGraph: PropTypes.object,
    login: PropTypes.object,
    savePortalReferenceJson: PropTypes.func,
    versionsFilter: PropTypes.object,
    templateType: PropTypes.string,
    defaultActions: PropTypes.object
};

function mapStateToProps(state, props) {
    return {
        template: state.openCloseSecondaryDrawer.template,
        questionnaireTemplate: state.questionnaireTemplate,
        questionnaireReference: state.questionnaireReference,
        questionnaireRequiredTokens: state.questionnaireRequiredTokens.requiredTokens,
        questionnaireTemplateGraph: state.questionnaireTemplateGraph,
        login: state.login
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeDrawer,
        questionnaireTemplateInit,
        getQuestionnaireTemplate,
        saveQuestionnaireTemplate,
        getQuestionnaireReference,
        getMetadataRequiredTokens,
        clearQuestionnaireReference,
        initGetTemplateList,
        //updateQuestionnaireTemplate
    }, dispatch);
}


const questionnaireSampleSurveyDrawerProps = {
    opensecondary: true,
    width: "70%",
    docked: false,
    anchor: "right"
};

function myParams(Component,props) {
    return props => <Component {...props} navHook={useNavigate()} />;
}
export default connect(mapStateToProps, mapDispatchToProps)(myParams(QuestionnaireSampleSurveyDrawer));
export {questionnaireSampleSurveyDrawerProps};
