import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {useNavigate} from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {openDrawer, publish, changeDate} from '../../actions/drawerActions';
import {QD_URL} from "../../constants/systemConstants";
import {getQuestionnaireExport} from "../../actions/questionnaireTemplateActions";
import {initGetTemplateList} from "../../actions/templateListActions";
import {initPageAndSortThenGetIntegrationLogsList} from "../../actions/integrationLogsActions";
import QuestionnaireRetireInActiveDrawer, {questionnaireRetireInActiveDrawerProps} from "../drawers/QuestionnaireRetireInActiveDrawer";
import QuestionnairePublishOrChangeDateDrawer, {questionnairePublishOrChangeDateDrawerProps} from "../drawers/QuestionnairePublishOrChangeDateDrawer";
import QuestionnaireNoteHistoryDrawer, {questionnaireNoteHistoryDrawerProps} from "../drawers/QuestionnaireNoteHistoryDrawer";
import QuestionnairePortalPreviewDrawer, {questionnairePortalPreviewDrawerProps} from "../drawers/QuestionnairePortalPreviewDrawer";
import QuestionnaireSampleSurveyDrawer, {questionnaireSampleSurveyDrawerProps} from "../drawers/QuestionnaireSampleSurveyDrawer";
import QuestionnaireStatisticsDrawer, {questionnaireStatisticsDrawerProps} from "../drawers/QuestionnaireStatisticsDrawer";
import QuestionnaireDeleteDraftDrawer, {questionnaireDeleteDraftDrawerProps} from "../drawers/QuestionnaireDeleteDraftDrawer";
import QuestionnaireScoringDrawer, {questionnaireScoringDrawerProps} from "../drawers/QuestionnaireScoringDrawer";
import * as TEMPLATE from "../../constants/templateConstants";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {withRouter} from "../../utilities/withRouter";


class GridActions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            menuOpen: false,
            menuItemList: []
        };
    }

    // componentDidMount() {
    //
    //     let menuItemList = [];
    //
    //     let key = this.props.gridListType.replaceAll("_","-").toLowerCase() + "-" + this.props.row.id + "-"; // Build a prefix for a menuItem unique key
    //
    //     switch(this.props.gridListType) {
    //         case TEMPLATE.GRID_LIST_TYPE.QUESTIONNAIRE_LIST:
    //             menuItemList.push({list: this.props.gridListType, key: key, text: "Edit", disabled: false});
    //             break;
    //         case TEMPLATE.GRID_LIST_TYPE.QUESTIONNAIRE_VERSIONS_LIST:
    //             break;
    //         case TEMPLATE.GRID_LIST_TYPE.CATEGORY_LIST:
    //             break;
    //         case TEMPLATE.GRID_LIST_TYPE.CATEGORY_VERSIONS_LIST:
    //             break;
    //         case TEMPLATE.GRID_LIST_TYPE.GROUP_LIST:
    //             break;
    //         case TEMPLATE.GRID_LIST_TYPE.GROUP_VERSIONS_LIST:
    //             break;
    //         case TEMPLATE.GRID_LIST_TYPE.QUESTION_LIST:
    //             break;
    //         case TEMPLATE.GRID_LIST_TYPE.QUESTION_VERSIONS_LIST:
    //             break;
    //         case TEMPLATE.GRID_LIST_TYPE.ACTION_LIST:
    //             break;
    //         case TEMPLATE.GRID_LIST_TYPE.ACTION_VERSIONS_LIST:
    //             break;
    //         case TEMPLATE.GRID_LIST_TYPE.TAG_LIST:
    //             break;
    //         case TEMPLATE.GRID_LIST_TYPE.INTEGRATION_LIST:
    //             break;
    //
    //     }
    //
    //     this.setState({menuItemList: menuItemList})
    //
    //
    // }

    handleIconButtonClick = (e) => {
        this.setState({anchorEl: e.currentTarget, menuOpen: true})
    }

    handleClickAway = (e) => {
        this.setState({menuOpen: false})
    }

    // handleMenuItemClick = (list, text, index, event) => {
    //     debugger;
    //     let row2 = this.props.row;
    //     debugger;
    // }




    openQuestionnaireRetireInActiveDrawer = (e) => {
        // Questionnaires published with a future date can be retired, or questionnaires past their published end date (InActive = true)
        this.props.openDrawer(questionnaireRetireInActiveDrawerProps, <QuestionnaireRetireInActiveDrawer questionnaireRow={this.props.row}/>, this.props.row);
        //e.preventDefault();
    };

    openQuestionnaireScoringDrawer = (e) => {
        this.props.openDrawer(questionnaireScoringDrawerProps, <QuestionnaireScoringDrawer row={this.props.row}/>, this.props.row);
        //e.preventDefault();
    };

    openQuestionnaireDeleteDraftDrawer = (e) => {
        this.props.openDrawer(questionnaireDeleteDraftDrawerProps, <QuestionnaireDeleteDraftDrawer/>, this.props.row);
        //e.preventDefault();
    };

    // Questionnaires in draft mode can be published.
    openQuestionnairePublishDateDrawer = () => {
        this.props.openDrawer(questionnairePublishOrChangeDateDrawerProps, <QuestionnairePublishOrChangeDateDrawer
            title={"Publish " + this.props.row.name}
            buttonLabel="PUBLISH"
            template={this.props.row}
            currentStartDate={""}
            action={this.props.publish}
            setLoadingState={this.props.setLoadingState}/>, this.props.row);
    };

    // Published or In-Active  questionnaires allow published dates to be changed.  In-Active questionares have start dates in the future or are past the end date.
    openQuestionnaireChangeDateDrawer = () => {

        this.props.openDrawer(questionnairePublishOrChangeDateDrawerProps, <QuestionnairePublishOrChangeDateDrawer
            title={"Change date " + this.props.row.name}
            buttonLabel="SUBMIT"
            action={this.props.changeDate}
            startDate={this.props.row.startDate}
            template={this.props.row}
            endDate={this.props.row.endDate}
            expirationDays={this.props.row.expirationDays}
            expirationDescription={this.props.row.expirationDescription}
            displayShortForm={this.props.row.displayShortForm}
            currentStartDate={""}
            setLoadingState={this.props.setLoadingState}
        />, this.props.row);
    };

    viewVersions = (key, name, nameQualifier) => {
        //alert("3");

        // ROUTE TO VERSIONS LIST (see Router.js)  create URL ie.. group-versions/:templateKey
        this.props.initGetTemplateList(this.props.versionsFilter, this.props.templateType, TEMPLATE.MODE.VERSIONS, key, name, nameQualifier, this.props.defaultActions);

        let path = QD_URL + "/" + this.props.templateType + "-versions/" + key;
        this.props.navHook(path, {replace: true});
    };

    openQuestionnaireNoteHistoryDrawer = () => {
        this.props.openDrawer(questionnaireNoteHistoryDrawerProps, <QuestionnaireNoteHistoryDrawer templateId={this.props.row.id}/>, this.props.row);
    };

    openQuestionnairePortalPreviewDrawer = () => {
        this.props.openDrawer(questionnairePortalPreviewDrawerProps, <QuestionnairePortalPreviewDrawer templateId={this.props.row.id}/>, this.props.row);
    };

    openQuestionnaireSampleSurveyDrawer = () => {
        this.props.openDrawer(questionnaireSampleSurveyDrawerProps,
            <QuestionnaireSampleSurveyDrawer
                templateId={this.props.row.id}
                savePortalReferenceJson={this.props.savePortalReferenceJson}
                versionsFilter={this.props.versionsFilter}
                templateType={this.props.templateType}
                defaultActions={this.props.defaultActions}
            />, this.props.row);
    };

    openQuestionnaireStatisticsDrawer = () => {
        // Survey statistis, displays selected published versions stats of templete id and totals for all published version of templete key
        this.props.openDrawer(questionnaireStatisticsDrawerProps, <QuestionnaireStatisticsDrawer templateId={this.props.row.id} templateKey={this.props.row.templateKey}/>, this.props.row);
    };

    openLink = (link) => {
        <withRouter navigate={link}/>;
        //this.context.router.history.push(link);
    };

    exportQuestionnaire = () => {
        this.props.getQuestionnaireExport(this.props.row);
    };

    getIsRetireDisabled = (row) => {
        // // Questionnaires published with a future date can be retired, or questionnaires past their published end date
        return !!row.isActive;
    };

    getRetireToolTip = (row) => {
        return this.getIsRetireDisabled(row) ? "Only questionnaires published witha a future date can be retired." : "";
        // Help text changed from: "You cannot retire an active questionnaire"
    };

    getIsEditDisabled = (label, row) => {
        let isDisabled = false;

        // The "Edit" action will be treated as a special case. Ignore other custom actions XYZ
        if (label === "Edit") {
            // Check the isEditable flag for this row. null/undefined values will NOT set isDisabled to true
            if (this.props.viewVersions) {
                isDisabled = (row.currentFlag === false);
            }
            else {
                isDisabled = (row.isEditable === false);
            }
        }

        return isDisabled;
    };

    viewIntegrationLogs = (id, name) => {

        //<Navigate to={"integration-logs/" + id} replace={false}/>

        this.props.initPageAndSortThenGetIntegrationLogsList(id, name, { pageNumber: this.props.integrationLogsFilter.pageNumber, pageSize: this.props.integrationLogsFilter.pageSize, name: this.props.integrationLogsFilter.name, direction: this.props.integrationLogsFilter.direction});
        //debugger;

        // let path = QD_URL + "/questionnaire-editor/" + templateKey + "/" + templateId + "/0"; // CloneMode 0=No, 1=Yes
        // this.props.navHook(path, {replace: true});

        //alert("1");
        // ROUTE TO INTEGRATION LOGS (see Router.js)
        let path = QD_URL + "/integration-logs/" + id;
        this.props.navHook(path, {replace: true});

        //<ArgoNavigateHook url={path}/>

        //this.props.viewIntegrationLogs(id, name);

       // this.props.navHook(path); //, {replace: true})
// <Navigate to={path} replace={true}><IntegrationLogsList/></Navigate>;


       // debugger;
        //this.props.initPageAndSortThenGetIntegrationLogsList(id, name, { pageNumber: this.props.integrationLogsFilter.pageNumber, pageSize: this.props.integrationLogsFilter.pageSize, name: this.props.integrationLogsFilter.name, direction: this.props.integrationLogsFilter.direction});
      //  debugger;
        //<withRouter navigate={QD_URL + "/integration-logs"}/>;
        //<IntegrationLogsList id={id}/>

        //this.context.router.history.push(QD_URL + "/integration-logs/" + id);
//debugger;
        //<withRouter navigate={QD_URL + "/search-tags"}/>;

    };

    getEditDisabledToolTip = (label, row) => {
        let showTooltip = this.getIsEditDisabled(label, row);
        let tooltipText = "";

        if (showTooltip) {
            let typeText = this.props.templateType;

            tooltipText = `A current draft for this ${typeText} already exists and any changes should be made to this draft`;
        }

        return tooltipText;
    };


    actionFunction = (value, link, index, row) => {
        switch (value) {
            case "Publish":
                return <MenuItem key={'publish'}onClick={this.openQuestionnairePublishDateDrawer}>Publish</MenuItem>;
            case "Retire":
                return <MenuItem key={'retire'} onClick={this.openQuestionnaireRetireInActiveDrawer}
                                 disabled={this.getIsRetireDisabled(row)}
                                 title={this.getRetireToolTip(row)}
                >Retire</MenuItem>;
            case "Scoring":
                return <MenuItem key={'scoring'} onClick={this.openQuestionnaireScoringDrawer}>Scoring</MenuItem>;
            case "Delete":
                return <MenuItem key={'deleteDraft'} onClick={this.openQuestionnaireDeleteDraftDrawer}>Delete Draft</MenuItem>;
            case "Change Effective Dates":
                return <MenuItem key={'changeDate'} onClick={this.openQuestionnaireChangeDateDrawer}>Change Publish Date</MenuItem>;
            default:
                // return (
                //     <MenuItem key={'view'} onClick={this.openLink.bind(this, link)}>View</MenuItem>
                // );
        }
    };

    render() {
        const {
            row,
            link,
            templateType,
            templateMode,
            customActions
        } = this.props;
        let menu = [];

        // If custom actions then add them to menu
        // debugger;
        if (customActions) {

            let uniqId = ((templateType === TEMPLATE.TYPE.INTEGRATION) || (templateType === TEMPLATE.TYPE.TAG)) ? row.id : row.templateKey;
            let questionnaireType = (templateType === TEMPLATE.TYPE.QUESTIONNAIRE) ? row.questionnaireType : "";

            // Loops thru custom actions and binds row details to function call
            customActions.map((action, index) => {
                if(action.label === 'Review') {
                    menu.push(
                        <MenuItem key={'customAction' + index}
                                        onClick={action.func.bind(this, uniqId, row.id.toString(), row.name, row, {altKey: false, ctrlKey: false})}
                                        title={this.getEditDisabledToolTip(action.label, row)}
                                        disabled={this.getIsEditDisabled(action.label, row)}>
                            {(this.props.templateMode === "versions" && this.getIsEditDisabled(action.label, row)) ? "Create draft from" : action.label}
                        </MenuItem>
                    )
                }
                else {
                    menu.push(<MenuItem key={"customAction" + index}
                                        onClick={action.func.bind(this, uniqId, row.id, templateMode, questionnaireType, row)}
                                        title={this.getEditDisabledToolTip(action.label, row)}
                                        disabled={this.getIsEditDisabled(action.label, row)}>
                        {(this.props.templateMode === "versions" && this.getIsEditDisabled(action.label, row)) ? "Create draft from" : action.label}
                    </MenuItem>);
                }
                return false;
            });
        }

        switch (templateType) {
            case TEMPLATE.TYPE.QUESTIONNAIRE:

                if (row.status) {

                    // Loop thru each rows actions based on the status of the questionnaire for that row
                    menu.push(row.status.actions.map((value, index) => {
                        return this.actionFunction(value, link + row.templateKey, index, row);
                    }));

                    // ROUTE TO VERSIONS LIST (see Router.js)  create URL ie.. group-versions/:templateKey
                    // This menu options is only available on the main questionnaire list which we know because its in CUTTENT mode.
                    if (templateMode === TEMPLATE.MODE.CURRENT) {
                        menu.push(
                            <MenuItem key={'versions'} onClick={this.viewVersions.bind(this, row.templateKey, row.name, row.nameQualifier)}>Versions</MenuItem>,
                        );
                    }

                    // These menu items are always available for questionnaires
                    menu.push(
                        <MenuItem key={'scoring'} onClick={this.openQuestionnaireScoringDrawer}>Scoring</MenuItem>,
                        <MenuItem key={'noteHistory'} onClick={this.openQuestionnaireNoteHistoryDrawer}>Note History</MenuItem>,
                        <MenuItem key={'portalPreview'} onClick={this.openQuestionnairePortalPreviewDrawer}>Portal Preview</MenuItem>,
                        <MenuItem key={'createRef'} onClick={this.openQuestionnaireSampleSurveyDrawer}>Sample Survey Creation</MenuItem>,
                        <MenuItem key={'questionnaireStatistics'} onClick={this.openQuestionnaireStatisticsDrawer}>Survey Statistics</MenuItem>,
                        <MenuItem key={'export'} onClick={this.exportQuestionnaire}>Export Questionnaire</MenuItem>
                    );

                }
                break;

            case TEMPLATE.TYPE.CATEGORY:
            case TEMPLATE.TYPE.GROUP:
            case TEMPLATE.TYPE.QUESTION:
            case TEMPLATE.TYPE.ACTION:

                if (templateMode === TEMPLATE.MODE.CURRENT) {
                    menu.push(
                        <MenuItem key={'versions'} onClick={this.viewVersions.bind(this, row.templateKey, row.name, row.nameQualifier)}>Versions</MenuItem>,
                    );
                }
                break;

            case TEMPLATE.TYPE.INTEGRATION:
                menu.push(
                    <MenuItem key={'logs'} onClick={this.viewIntegrationLogs.bind(this, row.id, row.name)}>Logs</MenuItem>
                );
                break;
            case TEMPLATE.TYPE.INTEGRATION_LOG:
                break;
            default:
                break;
        }



        return (this.state.menuItemList.lenght === 0 ? <div></div> :
                <div id="grid-actions-icon-button-container-div">
                    <div id="grid-actions-icon-button-div">
                        <ClickAwayListener onClickAway={this.handleClickAway}>
                        <IconButton id={"grid-actions-icon-button" + this.props.row.id} name={"grid-actions-icon-button" + this.props.row.id} onClick={this.handleIconButtonClick} >
                            <MoreVertIcon />
                        </IconButton>
                        </ClickAwayListener>
                    </div>

                        <Menu
                            id="lock-menu"
                            anchorEl={this.state.anchorEl}
                            open={this.state.menuOpen}

                            MenuListProps={{
                                'aria-labelledby': 'lock-button',
                                role: 'listbox',
                            }}
                        >
                            {menu}
                        </Menu>

            </div>
        );
    }
}

GridActions.defaultProps = {
};

GridActions.propTypes = {
    gridListType: PropTypes.string,
    row: PropTypes.object,
    link: PropTypes.string,
    customActions: PropTypes.array,
    type: PropTypes.string,
    integrationLogsFilter: PropTypes.object,
    templateType: PropTypes.string,
    templateMode: PropTypes.string,
    addVersionsAction: PropTypes.bool,
    versionsFilter: PropTypes.object,
    defaultActions: PropTypes.object,
    setLoadingState: PropTypes.func,
    savePortalReferenceJson: PropTypes.func,
    viewIntegrationLogs: PropTypes.func,
};

// GridActions.contextTypes = {
//     router: PropTypes.shape({
//         history: PropTypes.shape({
//             push: PropTypes.func.isRequired,
//             replace: PropTypes.func.isRequired
//         }).isRequired,
//         staticContext: PropTypes.object
//     }).isRequired
// };

function mapStateToProps(state, props) {
    return {
        integrationLogsFilter: state.filter['integrationLogs'],
        versionsFilter: state.filter[TEMPLATE.FILTER[TEMPLATE.MODE.VERSIONS][props.templateType]]
    };
}

function mapDispatchToProps(dispatch) {

    return bindActionCreators({
        openDrawer,
        getQuestionnaireExport,
        initPageAndSortThenGetIntegrationLogsList,
        initGetTemplateList,
        publish,
        changeDate}, dispatch);
}

function myParams(Component,props) {
    return props => <Component {...props} navHook={useNavigate()} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(myParams(GridActions));



// function myParams(Component,props) {
//     return props => <Component {...props} navHook={useNavigate()} />;
// }
//
// export default connect(mapStateToProps, mapDispatchToProps)(myParams(QuestionnaireList));
//export default withRouter(QuestionnaireList);

//export default connect(mapStateToProps, mapDispatchToProps)(myParams(QuestionnaireList));
//export default withRouter(QuestionnaireList);

